import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="navbar--offcanvas"
export default class extends Controller {
  static targets = ["content", "overlay", "menu"];
  connect() {}

  close() {
    this.contentTarget.classList.remove("translate-x-0");
    this.contentTarget.classList.add("-translate-x-full");
    this.overlayTarget.classList.add("hidden");
    this.menuTarget.classList.add("hidden");
  }

  open() {
    this.menuTarget.classList.remove("hidden");
    this.overlayTarget.classList.remove("hidden");
    this.contentTarget.classList.remove("-translate-x-full");
    this.contentTarget.classList.add("translate-x-0");
  }
}
