import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="navbar--scroll"
export default class extends Controller {
  handle(event) {
    if (window.scrollY > 10) {
      this.element.classList.add("fixed", "shadow-sm");
    } else {
      this.element.classList.remove("fixed", "shadow-sm");
    }
  }
}
